import React, { useContext } from 'react'
import { PageProps } from 'gatsby'
import {
  PageViewer,
  cleanPage,
  ReactBricksContext,
  types,
} from 'react-bricks/frontend'
import Layout from '../components/layout'
import Seo from '../components/seo'
import ErrorNoKeys from '../components/errorNoKeys'
import ErrorNoHomePage from '../components/errorNoHomePage'
import CookieConsent from 'react-cookie-consent'

interface ReactBricksPageProps {
  pageContext: {
    page: types.Page
    error: string
  }
}

const Page: React.FC<ReactBricksPageProps> = ({
  pageContext: { page, error },
}) => {
  const { pageTypes, bricks } = useContext(ReactBricksContext)

  // Clean the received content
  // Removes unknown or not allowed bricks
  const pageOk = page ? cleanPage(page, pageTypes, bricks) : null

  return (
    <Layout>
      {pageOk && (
        <>
          <Seo
            title={page.meta.title}
            description={page.meta.description}
            lang={page.meta.language}
          />
          <CookieConsent
            style={{
              background: 'white',
              color: '#444648',
              fontFamily: 'neue-haas-grotesk-text',
              borderTop: '1px solid #bcc8d3',
            }}
            buttonStyle={{
              backgroundColor: '#9c2a3b',
              color: 'white',
              fontFamily: 'neue-haas-grotesk-text',
              borderRadius: 10,
              padding: 10,
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            This website uses cookies to enhance the user experience. By
            continuing to visit this site you agree to our use of cookies.
          </CookieConsent>
          <PageViewer page={pageOk} />
        </>
      )}
      {error === 'NOKEYS' && <ErrorNoKeys />}
      {error === 'NOPAGE' && <ErrorNoHomePage />}
    </Layout>
  )
}

export default Page
